
import Vue from "vue";


export default Vue.extend({
  name: "CardGridObsCat",
  components:{

  },
  props: {
    title: {
      type: String,
    },
    img_src: {},
    video_src: {},
    propsdata: {
      type: Object,
    },
  },

  data: () => ({

  }),
  computed:{

  }
});
