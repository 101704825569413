
import Vue from "vue";
import CardGridSubDriv from "@/components/items/CardGridSubDriv.vue";

export default Vue.extend({
  name: "SubDrivingTaskGrid",
  components: {
    CardGridSubDriv,

  },
  props: {
    drivingtask: {
      type: Object,
    },

  },
  data: () => ({
    drivingtaskstate: Object,
    showBanner: false,

  }),
  computed: {
    getsdt: function (): JSON {
      let dt = this.$store.state.selecteddrivingtaskcatalog[parseInt(this.$route.params.dtid)-1]
      if(!this.$route.params.dtid)
        return Object.create(null)
      return dt.subdrivingtasks
    },
    getsdtnr: function (): number {
      let dt = this.$store.state.selecteddrivingtaskcatalog[parseInt(this.$route.params.dtid)-1]
      return dt.subdrivingtasks.length
    },
    getdtid: function (): number {
      return this.$store.state.selecteddrivingtaskcatalog[parseInt(this.$route.params.dtid)-1].id
    }
  },

  activated() {
    this.showBanner = true;
    if (this.getsdtnr == 0) {
      this.$router.replace("dt" + this.getdtid + "/sdt0")

    }
  },
  deactivated() {
    this.showBanner = false;
  }
});
