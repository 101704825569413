<template>
  <v-card height=heightOfCard
          shaped
          ripple
          @click="conditionalRouteTo"

  >
    <v-img
        :src=img_src
        class="align-end"
        :gradient="$vuetify.theme.dark ?  'to bottom, rgba(0,0,0,0), rgba(0,0,0,0) 50%, rgba(0,0,0,0.8)' :'to bottom, rgba(0,0,0,0), rgba(0,0,0,0) 60%, rgba(255,255,255,1)'"
        height="300px"
        @click="showSnackbar"
    >
      <template v-slot:placeholder>
        <v-row
            class="fill-height ma-0"
            justify="center"
        >
          <v-progress-linear
              indeterminate
          ></v-progress-linear>
        </v-row>
      </template>

      <v-card-actions>
        <v-card-title style="word-break: break-word; ">{{ title }}</v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon v-show="!getsitsubclasses">mdi-arrow-top-right</v-icon>
        </v-btn>
      </v-card-actions>

    </v-img>

    <v-card-text v-show="getsitsubclasses">
      <v-list dense>
        <v-subheader>Situation</v-subheader>
        <v-list-item-group
            color="primary"
        >
          <v-list-item
              v-for="(item, i) in getsitsubclasses"
              :key="i"
              :to="{name: 'ObservationCatGrid',
                params: {
                      dtid: dtid,
                      sdtid: sdtid,
                      subdrivingTask: propsdata,
                      sitsubid: item.id
                  }
              }"
          >
            <v-list-item-icon>
              <v-icon>mdi-arrow-top-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

    </v-card-text>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        shaped
        outlined
        color="primary"
        elevation="12"

    >
      Wählen Sie eine Situation.

    </v-snackbar>
  </v-card>

</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "CardGridSubDriv",
  props: {
    title: {
      type: String,
    },
    img_src: {},
    propsdata: {
      type: Object,
    },
    dtid: {
      type: Number,
    },
    sdtid: {
      type: Number,
    },
    sitsubid: {
      type: Number,
    }
  },

  data: () => ({
    heightOfCard: 300,
    snackbar: false,
    timeout: 2000,
  }),
  computed: {
    getsitsubclasses(){
      if(this.propsdata.situationsubclasses)
        return this.propsdata.situationsubclasses
      else return null;
    },
  }, created() {
    this.checkHeigtOfCard();
  }, methods: {
    checkHeigtOfCard() {
      if (this.getsitsubclasses) this.heightOfCard = 400;
    },
    conditionalRouteTo() {
      let dt = this.dtid;
      let sd = this.sdtid;
      if (!this.getsitsubclasses) {
        this.$router.push({name: 'ObservationCatGrid', params: {dtid: dt.toString(), sdtid: sd.toString()}})
      }
    },
    showSnackbar() {
      if (this.getsitsubclasses) {
        this.snackbar = true;
      }
    }
  }
});
</script>

<style lang="scss">
.img-gradients {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 60%, rgba(255, 255, 255, 1));
}

</style>