<template>
    <v-row>
      <v-col ref="colOfVid"
             cols="12"
             sm="12"
             md="8"
      >
        <VideoPlayer
            :video_src=video_src
            video_poster=""
            :idb=idb

        ></VideoPlayer>
      </v-col>
      <v-col
          cols="12"
          md="4"
          sm="12"
      >
        <v-card
            class="d-flex flex-column"
            outlined
            tile
            height="100%"
        >
          <v-card-title v-html="title+' -  Anforderung'" style="word-break: keep-all" class="subtitle-1 pa-2">
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text style="position: relative;
          overflow: auto; height: 100%;">
            <div class="videoPanelDescription">
            {{description}}
            </div>
          </v-card-text>
          <v-spacer></v-spacer>

        </v-card>
      </v-col>
    </v-row>
</template>

<script>
import Vue from "vue";
import VideoPlayer from "@/components/items/VideoPlayer";

export default Vue.extend({
  name: "VideoPanel",
  components: {
    VideoPlayer
  },
  data: () => ({
    videopanel: false,
  }),
  props: {
    title: {
      type: String,
    },
    video_src: {},
    video_poster: {},
    description: {},
    idb: Number,

  }
})
</script>

<style scoped>
.videoPanelDescription {
  position: inherit;
  height: 95%;
}
@media (min-width: 960px) {
  .videoPanelDescription {
    position: absolute;
  }
}
</style>