var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"height":"heightOfCard","shaped":"","ripple":""},on:{"click":_vm.conditionalRouteTo}},[_c('v-img',{staticClass:"align-end",attrs:{"src":_vm.img_src,"gradient":_vm.$vuetify.theme.dark ?  'to bottom, rgba(0,0,0,0), rgba(0,0,0,0) 50%, rgba(0,0,0,0.8)' :'to bottom, rgba(0,0,0,0), rgba(0,0,0,0) 60%, rgba(255,255,255,1)',"height":"300px"},on:{"click":_vm.showSnackbar},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"justify":"center"}},[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1)]},proxy:true}])},[_c('v-card-actions',[_c('v-card-title',{staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.getsitsubclasses),expression:"!getsitsubclasses"}]},[_vm._v("mdi-arrow-top-right")])],1)],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.getsitsubclasses),expression:"getsitsubclasses"}]},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Situation")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.getsitsubclasses),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":{name: 'ObservationCatGrid',
              params: {
                    dtid: _vm.dtid,
                    sdtid: _vm.sdtid,
                    subdrivingTask: _vm.propsdata,
                    sitsubid: item.id
                }
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-top-right")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)}),1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"shaped":"","outlined":"","color":"primary","elevation":"12"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Wählen Sie eine Situation. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }